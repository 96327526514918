import { Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { EmployeeDetails } from 'src/Models/EmployeeModels';

interface EmployeeSquadModalProps {
  isOpen: boolean;
  onClose: () => void;
  employeeName: string;
  employeeDetails?: EmployeeDetails;
}

const EmployeeSquadModal: React.FC<EmployeeSquadModalProps> = ({ isOpen, onClose, employeeName, employeeDetails }) => {
  const displayEmployeeName = employeeName.toUpperCase();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle bgcolor="#F6F6F6" color="#4A5568">
        {displayEmployeeName} - SQUAD ALLOCATION
      </DialogTitle>
      <DialogContent>
        {employeeDetails &&
          employeeDetails.currentEmployeeGroups &&
          employeeDetails.currentEmployeeGroups.map((employeeGroup, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <Typography color="#000000" sx={{ fontSize: '18px', fontWeight: 500, marginRight: '10px' }}>
                {index + 1}) {employeeGroup.groupName}
              </Typography>
              <Typography sx={{ fontSize: '10px', fontWeight: 300, marginRight: '10px' }}>
                {employeeGroup.groupMembers.filter((gm) => !gm.isDeleted).length} active group members
              </Typography>
              <Typography color="#5E5E5E" sx={{ fontSize: '16px', fontWeight: 500, marginRight: '10px' }}>
                Squad Leaders:
              </Typography>
              {employeeGroup.groupLeaders.map((groupLeader, glindex) => (
                <div key={groupLeader.groupLeaderId} style={{ backgroundColor: glindex % 2 === 0 ? '#EDF2F7' : '#FFFFFF' }}>
                  <Typography color="#5E5E5E" sx={{ fontSize: '14px', fontWeight: 400, marginRight: '10px', font: 'Roboto', lineHeight: '30px' }}>
                    {groupLeader.squadLeader.user.fullName}
                  </Typography>
                </div>
              ))}
              <Divider />
              <Divider />
            </div>
          ))}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={onClose} style={{ border: '1px solid' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeSquadModal;
