import { Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import React, { useMemo, useState } from 'react';
import { EmployeeLearningPathDTO } from 'src/Models/LearninpathModels';
import { TagCategory } from 'src/Models/TagModels';
import { EmployeeCertificateDTO } from 'src/services/CertificationService';

import LearningPathOverviewModal from '../LearningPaths/Modals/LearningPathOverviewModal';
import CertificateTagHistoryModal from '../manageCertificates/manageCertifications/CertificateHistoryModal/CertificateTagHistoryModal';
import { CERTIFICATION_TAG_COLOUR } from './MentionsShared';

interface Props {
  text: string;
  certData?: EmployeeCertificateDTO[];
  tagData?: TagCategory[];
  learningPathData?: EmployeeLearningPathDTO[];
  clickable: boolean;
}

const ProcessMentions: React.FC<Props> = ({ text, certData, tagData, clickable, learningPathData }) => {
  const [selectedCertificate, setSelectedCertificate] = useState<EmployeeCertificateDTO | null>(null);
  const [selectedLearningPath, setSelectedLearningPath] = useState<EmployeeLearningPathDTO | null>(null);
  const escapeRegex = (string: string) => string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

  const getCertMentionKey = (mention: EmployeeCertificateDTO) => {
    return `#${mention?.examName}${mention?.examName && ' - '}${mention.certificateName}`;
  };

  const getLearningPathMentionKey = (learningPath: string | null | undefined, phaseName: string) => {
    return `#${learningPath} - ${phaseName}`;
  };

  const learningPathMentionMap = useMemo(() => {
    if (!learningPathData) return {};

    const mentionMap = {};
    learningPathData.map((learningPath) => {
      learningPath.phases?.forEach((phase) => {
        const key = getLearningPathMentionKey(learningPath?.name!, phase?.name!);
        mentionMap[key] = learningPath;
      });
    });

    return mentionMap;
  }, [learningPathData]);

  const certMentionMap = useMemo(() => {
    if (!certData) return;
    return certData.reduce((acc, mention) => {
      const key = getCertMentionKey(mention);
      return {
        ...acc,
        [key]: mention,
      };
    }, {});
  }, [certData]);

  const getTagMentionKeys = (mention: TagCategory) => {
    if (!mention) return [];
    return mention.tags.map((tag) => {
      return `#${tag.tagName}`;
    });
  };

  const tagMentionMap = useMemo(() => {
    if (!tagData) return {};
    const map = {};
    tagData.forEach((mention) => {
      const keys = getTagMentionKeys(mention);

      keys.forEach((key) => {
        if (key.trim() !== '') {
          map[key] = mention;
        }
      });
    });

    return map;
  }, [tagData]);

  const combinedMentionMap = useMemo(
    () => ({ ...certMentionMap, ...tagMentionMap, ...learningPathMentionMap }),
    [certMentionMap, tagMentionMap, learningPathMentionMap],
  );

  const combinedMentionPattern = useMemo(
    () =>
      new RegExp(
        Object.keys(combinedMentionMap)
          .sort((a, b) => b.length - a.length)
          .map(escapeRegex)
          .join('|'),
        'g',
      ),
    [combinedMentionMap],
  );

  const renderParsedText = (): (string | JSX.Element)[] => {
    let output: (string | JSX.Element)[] = [];
    let lastIndex = 0;

    text.replace(combinedMentionPattern, (match, index) => {
      if (index > lastIndex) {
        output.push(text.substring(lastIndex, index));
      }
      let key;
      let displayLabel;
      if (combinedMentionMap[match]?.certificateId) {
        key = getCertMentionKey(combinedMentionMap[match] ?? {});
        displayLabel = key.startsWith('#') ? key.substring(1) : key;
      } else if (combinedMentionMap[match]?.id) {
        displayLabel = match.startsWith('#') ? match.substring(1) : key;
      } else {
        const tagKeys = getTagMentionKeys(combinedMentionMap[match] ?? {});
        displayLabel = tagKeys.map((tagKey) => (tagKey.startsWith('#') ? tagKey.substring(1) : tagKey)).join(', ');
      }

      output.push(
        <Chip
          size="small"
          key={match}
          label={
            <Typography
              sx={{
                whiteSpace: 'normal',
                color: combinedMentionMap[match].id ? '#00695C' : 'white',
                m: '4px',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '13px',
              }}>
              {displayLabel}
            </Typography>
          }
          sx={{
            height: '100%',
            color: 'white',
            m: '4px',
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.5)',
            borderColor: combinedMentionMap[match].id ? '#00695C' : 'none',
            backgroundColor: combinedMentionMap[match].certificateId
              ? CERTIFICATION_TAG_COLOUR
              : combinedMentionMap[match].id
              ? 'none'
              : combinedMentionMap[match].categoryColor,
          }}
          variant={combinedMentionMap[match].id ? 'outlined' : 'filled'}
          clickable={clickable}
          onClick={() => {
            const selectedCert = certMentionMap?.[match];
            clickable && setSelectedCertificate(selectedCert);

            const selectedPath = learningPathMentionMap?.[match];
            clickable && setSelectedLearningPath(selectedPath);
          }}
        />,
      );

      lastIndex = index + match.length;
      return match;
    });

    if (lastIndex < text.length) {
      output.push(text.substring(lastIndex));
    }
    return output;
  };

  return (
    <>
      {renderParsedText()}
      {selectedCertificate && <CertificateTagHistoryModal onClose={() => setSelectedCertificate(null)} employeeCertificate={selectedCertificate} />}
      {selectedLearningPath && (
        <LearningPathOverviewModal onClose={() => setSelectedLearningPath(null)} employeeLearningPath={selectedLearningPath} />
      )}
    </>
  );
};

export default React.memo(ProcessMentions);
