import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  Button,
  CircularProgress,
  Grid,
  SortDirection,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { DashboardEmployee } from 'src/Models/EmployeeModels';

import EmployeeOverviewTableRow from '../dashboardShared/EmployeeOverviewTableRow';
import { NarrowTableCell } from '../dashboardShared/NarrowTableCell';
import ColumnHeader from '../shared/ColumnHeader';
import EmployeeDetailFilter from '../shared/EmployeeDetailFilter';
import AssignToSquadModal from './AssignToSquadModal';
import ClientManagementTableAreaTagFilter from './ClientManagementTableAreaTagFilter';
import { useClientManagementDashboardContext } from './context/ClientManagementDashboardContext';

const ClientManagementTableArea: React.FC = () => {
  const [sortByDirection, setSortByDirection] = useState<SortDirection>('asc');
  const [sortByOption, setSortByOption] = useState({
    value: 'fullName',
    name: undefined,
  });
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [filterBarOpen, setFilterBarOpen] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [employeesToAddToGroup, setEmployeesToAddToGroup] = useState<DashboardEmployee[]>([]);

  const { employees, isLoading } = useClientManagementDashboardContext();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleDetailFilterStatusChange = (status: boolean) => {
    setIsFilterApplied(status);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getSortDirection = (key: string) => (key === sortByOption.value ? sortByDirection : false);

  const handleSortClicked = (option: string) => {
    if (option === sortByOption.value) {
      setSortByDirection((previousSortDirection) => {
        return previousSortDirection === 'asc' ? 'desc' : 'asc';
      });
    } else {
      setSortByOption((previousSortByOption) => {
        return { ...previousSortByOption, value: option };
      });
      setSortByDirection('asc');
    }
  };

  const sortEmployees: any = () => {
    let filtered: any = employees;
    if (sortByOption.value) {
      switch (sortByOption.value) {
        case 'status':
          filtered.sort((a: any, b: any) => {
            const statusComparison = sortByDirection === 'asc' ? 1 : -1;
            if (a.status.sortIndex !== b.status.sortIndex) {
              return (a.status.sortIndex > b.status.sortIndex ? 1 : -1) * statusComparison;
            } else {
              return a.fullName.localeCompare(b.fullName);
            }
          });
          break;
        case 'daysOnSquad':
          filtered.sort(function (a: any, b: any) {
            const daysComparison = sortByDirection === 'asc' ? 1 : -1;
            if (a.daysOnSquad.days !== b.daysOnSquad.days) {
              return (a.daysOnSquad.days - b.daysOnSquad.days) * daysComparison;
            } else {
              return a.fullName.localeCompare(b.fullName);
            }
          });
          break;
        case 'title':
          filtered.sort((a: any, b: any) => {
            const titleComparison = sortByDirection === 'asc' ? 1 : -1;
            if (a.jobTitle.title !== b.jobTitle.title) {
              return (a.jobTitle.title > b.jobTitle.title ? 1 : -1) * titleComparison;
            } else {
              return a.fullName.localeCompare(b.fullName);
            }
          });
          break;
        case 'latestFeedbackCategory':
          filtered.sort((a: any, b: any) => {
            const latestFeedbackCategoryComparison = sortByDirection === 'asc' ? 1 : -1;
            if (a.latestFeedbackCategory !== b.latestFeedbackCategory) {
              return (a.latestFeedbackCategory > b.latestFeedbackCategory ? 1 : -1) * latestFeedbackCategoryComparison;
            } else {
              return a.fullName.localeCompare(b.fullName);
            }
          });
          break;
        default:
          filtered.sort((a: any, b: any) => {
            const fullNameComparison = sortByDirection === 'asc' ? 1 : -1;
            return a.fullName.localeCompare(b.fullName) * fullNameComparison;
          });
          break;
      }
    }
    return filtered;
  };

  const handleAssignToSquad = () => {
    setModalOpen(!modalOpen);
  };

  const handleOpenFilterPanel = () => {
    setFilterBarOpen(!filterBarOpen);
  };

  const currentItems = sortEmployees().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleCheckBoxChecked = (employee: DashboardEmployee) => {
    setEmployeesToAddToGroup((prev = []) => (prev.includes(employee) ? prev.filter((id) => id !== employee) : [...prev, employee]));
  };

  const resetSelectedEmployees = () => {
    setEmployeesToAddToGroup([]);
  };

  return (
    <>
      <EmployeeDetailFilter
        drawerOpen={filterBarOpen}
        employeeList={employees}
        closeFilterComponent={handleOpenFilterPanel}
        onFilterChange={handleDetailFilterStatusChange}
        isIncommingTab={true}
      />
      <Grid container id="table-controls">
        <Grid item id="main-controls" lg={12} rowGap={1}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} lg={6}>
              <Grid container id="search-controls" gap="6px" alignItems="center">
                <Grid item xs={12} sm={'auto'} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <ClientManagementTableAreaTagFilter />
                  <FilterAltIcon
                    fontSize="large"
                    sx={{
                      paddingLeft: '20px',
                      color: isFilterApplied ? 'lightBlue' : 'gray',
                      cursor: 'pointer',
                    }}
                    onClick={handleOpenFilterPanel}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid container justifyContent="flex-end" alignItems="center" sx={{ padding: '6px' }}>
                <Button onClick={handleAssignToSquad} variant="outlined" startIcon="+" disabled={employeesToAddToGroup.length < 1}>
                  Assign To Squad
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid id="employee-overview-table" container>
        <Grid item width={'100%'}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <NarrowTableCell></NarrowTableCell>
                  <NarrowTableCell></NarrowTableCell>
                  <NarrowTableCell></NarrowTableCell>
                  <NarrowTableCell>
                    <ColumnHeader
                      title="Name"
                      display={'flex'}
                      justifyContent={'flex-start'}
                      tooltipText="Order by Name"
                      sx={{ pl: 0 }}
                      columnKey="fullName"
                      onClick={handleSortClicked}
                      sortDirection={getSortDirection}
                    />
                  </NarrowTableCell>
                  <NarrowTableCell>
                    <ColumnHeader
                      title="Title"
                      display={'flex'}
                      justifyContent={'flex-start'}
                      tooltipText="Order by job title"
                      sx={{ pl: 0 }}
                      columnKey="title"
                      onClick={handleSortClicked}
                      sortDirection={getSortDirection}
                    />
                  </NarrowTableCell>
                  <NarrowTableCell>
                    <ColumnHeader title="Squad" columnKey="squad" />
                  </NarrowTableCell>
                  <NarrowTableCell>
                    <ColumnHeader
                      title="Onboarding TFO in"
                      tooltipText="Order by days on the team"
                      columnKey="daysOnSquad"
                      onClick={handleSortClicked}
                      sortDirection={getSortDirection}
                    />
                  </NarrowTableCell>
                  <NarrowTableCell>
                    <ColumnHeader
                      title="Today's feedback"
                      tooltipText="Order by Today's feedback"
                      columnKey="latestFeedbackCategory"
                      onClick={handleSortClicked}
                      sortDirection={getSortDirection}
                    />
                  </NarrowTableCell>
                  <NarrowTableCell>
                    <ColumnHeader
                      title="Status"
                      tooltipText="Order by status"
                      columnKey="status"
                      onClick={handleSortClicked}
                      sortDirection={getSortDirection}
                    />
                  </NarrowTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  currentItems.map((employee: any) => (
                    <EmployeeOverviewTableRow
                      key={employee.employeeId}
                      employee={employee}
                      handleCheckBoxChecked={handleCheckBoxChecked}
                      selectedEmployees={employeesToAddToGroup}
                    />
                  ))
                )}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={employees.length}
              page={page}
              showFirstButton
              showLastButton
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>
      <AssignToSquadModal
        open={modalOpen}
        onClose={handleAssignToSquad}
        selectedEmployees={employeesToAddToGroup}
        resetSelectedEmployees={resetSelectedEmployees}
      />
    </>
  );
};

export default ClientManagementTableArea;
