import ClearIcon from '@mui/icons-material/Clear';
import { Button, Grid, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect } from 'react';
import { EmployeeService } from 'src/services/EmployeeService';
import { getDateTime } from 'src/utilities/DateUtilities';
import { SquadsContext } from 'src/utilities/SquadsContext';

import { useClientManagementDashboardContext } from '../context/ClientManagementDashboardContext';
import { useClientManagementFilterContext } from '../context/ClientManagementFilterContext';

const employeeService = new EmployeeService();

const IncommingEmployeeGlobalDateFilter: React.FC = (props: any) => {
  const { setEmployees } = useClientManagementDashboardContext();

  const { startDate, endDate, setStartDate, setEndDate, setClearAll } = useClientManagementFilterContext();

  const { squad } = React.useContext(SquadsContext);

  const getAllEmployees = async () => {
    const response = await employeeService.GetAllIncommingEmployees(+squad.squadId);
    setEmployees(response);
  };

  useEffect(() => {
    getAllEmployees();
    setEmployeesResult();
  }, [startDate, endDate]);

  const setEmployeesResult = async () => {
    const employees = await employeeService.GetAllIncommingEmployees(+squad.squadId);
    if (startDate && endDate) {
      //filter table area
      setEmployees(
        employees.filter((x) => {
          const availableDate = new Date(x.availableDate);
          return availableDate >= new Date(startDate) && availableDate <= new Date(endDate);
        }),
      );
    }
  };

  const handleDisabledButton = () => {
    return startDate === null && endDate === null;
  };

  const onClearFilters = async () => {
    setStartDate(null);
    setEndDate(null);
    setClearAll(true);
    await getAllEmployees();
  };

  return (
    <Grid container direction="row" spacing={0.5}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid item xs={4}>
          <DatePicker
            label="Start date"
            InputProps={{ size: 'small' }}
            value={startDate}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue: any) => {
              if (getDateTime(newValue) > props.endDate) {
                newValue = props.endDate;
              }
              setStartDate(newValue);
            }}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={4}>
          <DatePicker
            label="End date"
            InputProps={{ size: 'small' }}
            value={endDate}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue: any) => {
              if (getDateTime(newValue) < getDateTime(startDate)) {
                newValue = startDate;
              }
              setEndDate(newValue);
            }}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </Grid>
      </LocalizationProvider>
      <Grid item xs={4}>
        <Button size="small" startIcon={<ClearIcon />} disabled={handleDisabledButton()} onClick={onClearFilters}>
          Clear
        </Button>
      </Grid>
    </Grid>
  );
};

export default IncommingEmployeeGlobalDateFilter;
