import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import FlagIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { EditorState, convertToRaw } from 'draft-js';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useEditor } from 'src/components/Editor/EditorContext';
import LearningPathPhaseCheckList from 'src/components/LearningPaths/LearningPathPhaseCheckList';
import { useLearningPathContext } from 'src/components/LearningPaths/context/LearningPathContext';
import { AddFeedbackMentions } from 'src/components/mentions/AddFeedbackMentions';
import { MentionComponent, useStyles } from 'src/components/mentions/MentionsShared';
import LoadingButton from 'src/components/shared/LoadingButton';
import { useSnackbar } from 'src/components/shared/SnackbarContext';
import { FeedbackPayload, FeedbackService } from 'src/services/FeedbackService';
import { Category } from 'src/services/FeedbackType';
import { SquadsContext } from 'src/utilities/SquadsContext';

import { DEFAULT_SENTIMENT_ID, FEEDBACK_FORM_EDITOR_KEY } from '../../../../utilities/Constants';
import mapJobTitleToCategory from '../../../../utilities/MapJobTitleToCategory';
import FlagTooltip from '../../../shared/FlagTooltip';
import { useEntityContext } from '../../context/EntityContext';
import { useFeedbackContext } from '../../context/FeedbackContext';
import FeedbackFollowUpForm from './FeedbackFollowUpForm';
import SentimentSelector from './components/SentimentSelector';

interface FeedbackFormProps {
  categoryList: Category[];
  refreshData: () => void;
  defaultCategoryId: number;
}

const feedbackService = new FeedbackService();

const FeedbackForm = ({ categoryList, refreshData, defaultCategoryId }: FeedbackFormProps) => {
  const { squad } = useContext(SquadsContext);
  const { employee, getEmployees, isFocused, setFocus, setIsUpdated, isUpdated } = useEntityContext();
  const { getFeedbackItems } = useFeedbackContext();
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = React.useState(false);
  const { openSnackbar } = useSnackbar();
  const {
    selectedLearningPathPhase,
    selectedLearningPathName,
    checkListItems,
    setSelectedLearningPathName,
    setSelectedLearningPathPhase,
    toggleChecklist,
  } = useLearningPathContext();

  const { resetEditorState, ref: editorRef, onEditorClick, editorState, setEditorState } = useEditor();

  const handleEditorChange = useCallback((newEditorState: EditorState, props?: any) => {
    setEditorState(newEditorState);

    const rawContentState = convertToRaw(newEditorState.getCurrentContent());

    // Convert the RawDraftContentState to a JSON string
    const initialEditorStateJson = JSON.stringify(rawContentState);
    const storageKey = `editorState_${employee?.employeeId}`;
    sessionStorage.setItem(storageKey, initialEditorStateJson);
    props.onChange(newEditorState.getCurrentContent().getPlainText().trim());
    window.dispatchEvent(new CustomEvent('onFeedbackInputChange', { detail: { value: newEditorState.getCurrentContent().getPlainText() } }));
  }, []);

  const saveFormStateToSessionStorage = (state: FeedbackPayload) => {
    sessionStorage.setItem(`feedbackForm_${employee?.employeeId}`, JSON.stringify(state));
  };
  const loadFormStateFromSessionStorage = () => {
    const savedState = sessionStorage.getItem(`feedbackForm_${employee?.employeeId}`);

    return savedState ? JSON.parse(savedState) : null;
  };
  const savedState = loadFormStateFromSessionStorage();

  const { classes } = useStyles();

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionTrigger: '#',
      mentionPrefix: '#',
      mentionComponent: MentionComponent,
      entityMutability: 'IMMUTABLE',
    });
    return { plugins: [mentionPlugin], MentionSuggestions: mentionPlugin.MentionSuggestions };
  }, []);

  const initialState: Partial<FeedbackPayload> = {
    categoryId: (defaultCategoryId ?? 0) > 0 ? defaultCategoryId : undefined,
    squadId: parseInt(squad.squadId),
    employeeId: employee?.employeeId ?? 0,
    createdDate: new Date().toISOString(),
    sentimentId: DEFAULT_SENTIMENT_ID,
    createdBy: '',
    description: '',
    followUp: false,
    feedbackFollowUp: null,
  };

  const methods = useForm<FeedbackPayload>({
    defaultValues: {
      squadId: squad.squadId,
      sentimentId: DEFAULT_SENTIMENT_ID,
      categoryId: (defaultCategoryId ?? 0) > 0 ? defaultCategoryId : undefined,
      employeeId: employee?.employeeId,
      createdDate: new Date(),
      ...savedState,
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = methods;
  const formValues = watch();
  const followUp = watch('followUp');

  let categorisedJobTitle;

  const onSubmit = async (data: FeedbackPayload) => {
    if (employee?.jobTitle) {
      categorisedJobTitle = mapJobTitleToCategory(employee?.jobTitle);
    } else categorisedJobTitle = null;

    const sentData = {
      ...data,
      categoryId: `${data.categoryId}`,
      title: '',
      assignedRole: categorisedJobTitle,
      phaseId: selectedLearningPathPhase?.id ?? 0,
      updatedChecklists: checkListItems,
    };

    try {
      await feedbackService.create(sentData);

      setIsSubmittedSuccessfully(true);
      getFeedbackItems(true);
      setTimeout(() => setIsSubmittedSuccessfully(false), 2000);
      reset(
        { ...initialState, categoryId: formValues.categoryId },
        { keepValues: false, keepDirty: false, keepDefaultValues: false, keepIsSubmitted: false },
      );
      resetEditorState();
      getEmployees();
      const search = document.getElementById('entity-search');
      search?.focus();
      refreshData();
      resetLearningPath();
      setIsUpdated(!isUpdated);
    } catch (error) {
      console.error({ error });
      openSnackbar('error', 'Error submitting feedback');
    }
  };

  const resetLearningPath = () => {
    setSelectedLearningPathName('');
    setSelectedLearningPathPhase({});
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.ctrlKey && event.key === 'Enter') {
      handleSubmit(onSubmit)();
    }

    if (event.ctrlKey && event.keyCode === 40) {
      event.preventDefault();
      setValue('sentimentId', 1);
    }

    if (event.ctrlKey && event.keyCode === 38) {
      event.preventDefault();
      setValue('sentimentId', 3);
    }
  };

  useEffect(() => {
    saveFormStateToSessionStorage(formValues);
  }, [formValues]);

  useEffect(() => {
    if (isFocused) {
      editorRef.current?.focus();
    }
    setFocus(false);
  }, [editorRef, isFocused]);

  return (
    <FormProvider {...methods}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={selectedLearningPathName ? 9 : 12}>
          <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown} style={{ width: '100%' }}>
            <Stack sx={{ width: selectedLearningPathName ? 'auto' : '100%' }} spacing={1.5}>
              <Stack direction={{ xs: 'column', md: 'row' }}>
                <Grid container spacing={0.5} alignItems="center">
                  <Grid item xs={12} md={3}>
                    <FormControl
                      required
                      sx={{
                        width: '100%',

                        '& .MuiFormControl-root': {
                          width: '100%',
                        },
                        '@media (min-width: 600px)': {
                          width: '100%',
                        },
                      }}
                      size="small">
                      <InputLabel id="select-label">Feedback Category</InputLabel>

                      <Controller
                        name="categoryId"
                        control={control}
                        rules={{
                          required: 'Feedback category is required.',
                          pattern: { value: /[1-9]\d*$/, message: 'Feedback category is required.' },
                        }}
                        render={({ field }) => (
                          <Select
                            labelId="select-label"
                            id="feedback-select"
                            label="Feedback Category"
                            {...field}
                            error={!!errors.categoryId}
                            value={field.value}>
                            {categoryList.map((category) => (
                              <MenuItem key={category.categoryId} value={category.categoryId}>
                                {category.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />

                      {errors.categoryId && <FormHelperText error>{errors.categoryId.message}</FormHelperText>}
                    </FormControl>
                  </Grid>

                  <Grid item sx={{ maxWidth: { xs: '50%' } }}>
                    <SentimentSelector />
                  </Grid>
                  <Grid item sx={{ maxWidth: { xs: '90%' } }}>
                    <FeedbackFollowUpForm />
                  </Grid>

                  <Grid item>
                    <FlagTooltip followUp={followUp} handleConfirm={() => setValue('followUp', !followUp)}>
                      {followUp ? <FlagIcon fontSize="medium" /> : <FlagOutlinedIcon fontSize="medium" />}
                    </FlagTooltip>
                  </Grid>
                </Grid>
              </Stack>

              <Controller
                name="description"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Feedback notes is required.',
                }}
                render={({ field }) => (
                  <div className={classes.editor} onClick={onEditorClick}>
                    <Editor
                      ref={editorRef}
                      editorKey={FEEDBACK_FORM_EDITOR_KEY}
                      editorState={editorState}
                      onChange={(editorState: EditorState) => handleEditorChange(editorState, field)}
                      plugins={plugins}
                      placeholder="Feedback notes"
                      preserveSelectionOnBlur={true}
                    />
                    <AddFeedbackMentions editorRef={editorRef} MentionSuggestions={MentionSuggestions} />
                  </div>
                )}
              />

              {errors.description && <FormHelperText error>{errors.description.message}</FormHelperText>}

              <Grid container display="flex" justifyContent="flex-end" xs={6} md={12}>
                <Grid item>
                  <LoadingButton isSubmittedSuccessfully={isSubmittedSuccessfully} isSubmitting={isSubmitting} label="Submit" type="submit" />
                </Grid>
              </Grid>
            </Stack>
          </form>
        </Grid>

        {selectedLearningPathPhase && selectedLearningPathName && toggleChecklist && (
          <Grid item xs={12} md={3}>
            <LearningPathPhaseCheckList />
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
};

export default FeedbackForm;
