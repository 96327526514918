import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
interface IEmployeeProfileHeaderProps {
  fullName: string;
  jobTitle: string;
  isActive: boolean;
}

const EmployeeProfileHeader = (props: IEmployeeProfileHeaderProps) => {
  const { fullName, jobTitle, isActive } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Stack alignItems={isMobile ? 'center' : 'start'}>
        <Typography variant="h5" component="div" align="center">
          <b>{fullName}</b>
          {!isActive && (
            <Typography variant="h6" component={'span'} color={'#5E5E5E'}>
              (Deactivated)
            </Typography>
          )}
        </Typography>
        <Typography variant="h6" color="#5D5D5D" component="div" align="center">
          {jobTitle}
        </Typography>
      </Stack>
    </>
  );
};
export default EmployeeProfileHeader;
