import { Autocomplete, Box, Container, Divider, Paper, Stack, TextField } from '@mui/material';
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { LearningPathProvider } from 'src/components/LearningPaths/context/LearningPathContext';
import { useFeatureToggle } from 'src/components/featureToggle/FeatureToggle';
import { CategoryService } from 'src/services/CategoryService';
import { Category } from 'src/services/FeedbackType';
import { SquadGroup, SquadMember } from 'src/services/SquadGroupService';
import ErrorBoundary from 'src/shared/ErrorBoundary';
import { SquadLeaderContext } from 'src/utilities/SquadLeaderContext';
import { SquadsContext } from 'src/utilities/SquadsContext';

import { FeedbackTags } from '../FeedbackTags';
import { useEntityContext } from '../context/EntityContext';
import { FeedbackProvider } from '../context/FeedbackContext';
import FeedbackForm from './form/FeedbackForm';
import GroupFeedbackForm from './form/GroupForm';
import FeedbackHeader from './header/FeedbackHeader';
import GroupFeedbackHeader from './header/GroupFeedbackHeader';
import ReactivateEmployee from './header/components/ReactivateEmployee';
import FeedbackHistory from './history/FeedbackHistory';
import GroupFeedbackHistory from './history/components/GroupFeedbackHistory';
import WordCloud from './wordCloud/WordCloud';
import { LearningPathFallBackService } from 'src/services/LearningPathFallBackService';
import { EmployeeLearningPathBasicDTO } from 'src/Models/LearninpathModels';
import { ReducedEmployee } from 'src/Models/EmployeeModels';

interface FeedbackProps {
  employee: ReducedEmployee | null;
  group: SquadGroup | null;
}

const categoryService = new CategoryService();

const Feedback = ({ employee, group }: FeedbackProps) => {
  const { wordCloud } = useFeatureToggle();
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [refreshData, setRefreshData] = React.useState(false);
  const [employeeLearningPaths, setEmployeeLearningPaths] = useState<EmployeeLearningPathBasicDTO[]>([]);
  const { isUpdated } = useEntityContext();
  const { squad } = useContext(SquadsContext);
  const { squadLeader } = React.useContext(SquadLeaderContext);
  const defaultCategoryId = React.useMemo(() => {
    const squadLeaderDefaultCategoryId = squadLeader.find((leader) => leader.squadId === squad.squadId)?.categoryId;
    return squadLeaderDefaultCategoryId != null ? squadLeaderDefaultCategoryId : 0;
  }, [squad.squadId]);

  const learningpathService = new LearningPathFallBackService();

  const getPaths = async () => {
    if (employee && employee.employeeId) {
      const employeeLearningPathData = await learningpathService.getEmployeeLearningPaths(employee?.employeeId!);
      if (employeeLearningPathData) setEmployeeLearningPaths(employeeLearningPathData);
    }
  };

  const handleRefreshData = () => {
    setRefreshData(!refreshData);
  };

  const loadCategories = useCallback(async () => {
    if (squad.squadId === '') return;
    try {
      const response = await categoryService.getAllCategoriesForSquad(squad.squadId);
      setCategoryList(response);
    } catch (e) { }
  }, [squad.squadId]);

  useEffect(() => {
    getPaths();
  }, [employee, isUpdated]);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  if (!employee && !group) return null;

  const FeedbackFormComponent = () =>
    useMemo(
      () => (
        <ErrorBoundary id={'FeedbackFormComponent'} showNavBack={false}>
          <FeedbackForm categoryList={categoryList} refreshData={handleRefreshData} defaultCategoryId={defaultCategoryId} />
        </ErrorBoundary>
      ),
      [categoryList, defaultCategoryId],
    );

  const EmployeeFeedback = () =>
    useMemo(
      () => (
        <Paper sx={{ width: '100%', maxWidth: '100%' }} key={employee?.emailAddress}>
          {employee?.isDeleted && <ReactivateEmployee />}
          <FeedbackHeader />
          <Stack sx={{ height: 'calc(100vh - 170px)', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
            <FeedbackTags employee={employee} refreshData={refreshData} showImported={false} clickable={true} />
            {wordCloud && <WordCloud />}
            <Divider />
            <FeedbackHistory refreshData={handleRefreshData} FeedbackForm={FeedbackFormComponent} employeeLearningPaths={employeeLearningPaths} />
          </Stack>
        </Paper>
      ),
      [refreshData, employeeLearningPaths, employee, wordCloud],
    );

  const GroupFeedback = () =>
    useMemo(
      () => (
        <Stack sx={{ background: '#FFF', width: '100%' }} key={group?.groupId}>
          <GroupFeedbackHeader />
          <Stack py={2} px={'8px'} sx={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
            {group && group.groupMembers?.length > 0 && (
              <Autocomplete
                multiple
                id="groupMembers-readOnly"
                options={group.groupMembers}
                value={group.groupMembers}
                isOptionEqualToValue={(option, value) => option.employeeFullName === value.employeeFullName}
                getOptionLabel={(option: any) => option.employeeFullName}
                readOnly
                renderInput={(params) => <TextField {...params} label="Group members" disabled />}
                renderOption={(props, option: SquadMember) => (
                  <Box component="li" {...props} key={option.employeeId}>
                    {option.employeeFullName}
                  </Box>
                )}
              />
            )}
            <GroupFeedbackForm categoryList={categoryList} defaultCategoryId={defaultCategoryId} />
            <GroupFeedbackHistory />
          </Stack>
        </Stack>
      ),
      [group, categoryList, defaultCategoryId],
    );

  return (
    <Box key={employee?.employeeId} width={'100%'}>
      <FeedbackProvider employeeId={employee?.employeeId} groupId={group?.groupId} squadId={parseInt(squad.squadId)}>
        <LearningPathProvider>
          <Container disableGutters sx={{ display: 'flex', height: 'calc(100vh - 100px)', width: '100%', px: 1.5 }}>
            {employee && <EmployeeFeedback />}
            {group && <GroupFeedback />}
          </Container>
        </LearningPathProvider>
      </FeedbackProvider>
    </Box>
  );
};

export default memo(Feedback);
