import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Autocomplete, TextField, CircularProgress } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { DashboardEmployee } from 'src/Models/EmployeeModels';
import { SquadGroup } from 'src/Models/SquadModels';
import { PostSquadList, SquadGroupService } from 'src/services/SquadGroupService';
import { SquadsContext } from 'src/utilities/SquadsContext';

import { useFilterContext } from '../activeEmployeesDashboard/context/FilterContext';
import { useClientManagementFilterContext } from './context/ClientManagementFilterContext';

export interface AssignToSquadModalProps {
  open: boolean;
  onClose: () => void;
  selectedEmployees: DashboardEmployee[];
  resetSelectedEmployees: () => void;
}

const AssignToSquadModal: React.FC<AssignToSquadModalProps> = ({ open, onClose, selectedEmployees, resetSelectedEmployees }) => {
  const [selectedSquadSquadGroup, setSelectedSquadGroup] = useState<SquadGroup>();
  const [availableSquadGroups, setAvailableSquadGroups] = useState<SquadGroup[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const { squad } = useContext(SquadsContext);
  const squadGroupService = new SquadGroupService();
  const { updateSquadData } = useClientManagementFilterContext();
  const { updateSquadData: updateActiveSquad } = useFilterContext();

  const getSquadGroups = async () => {
    var response = await squadGroupService.getSquadGroupsBySquadId(squad.squadId);
    setAvailableSquadGroups(response);
  };

  const handleAssignEmployeesToSquad = async () => {
    if (selectedSquadSquadGroup) {
      var res: PostSquadList = {
        employeeIds: selectedEmployees.map((x) => x.employeeId),
        squadGroupId: selectedSquadSquadGroup?.groupId,
      };
      var response = await squadGroupService.AddListOfEmployeesToSquadGroup(res, squad.squadId);
      if (response) {
        updateSquadData();
        updateActiveSquad();
        resetSelectedEmployees();
        handleModalClose();
      }
    }
  };

  const handleModalClose = () => {
    onClose();
  };

  useEffect(() => {
    if (selectedSquadSquadGroup) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedSquadSquadGroup]);

  useEffect(() => {
    getSquadGroups();
  }, []);

  const onSelectedSquadGroupChange = (value: any) => {
    setSelectedSquadGroup(value);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Rotate Squad Group</DialogTitle>
      <DialogContent>
        {availableSquadGroups ? (
          <Autocomplete
            sx={{ marginTop: '10px' }}
            options={availableSquadGroups}
            getOptionLabel={(squadGroup: SquadGroup) => squadGroup.groupName}
            multiple={false}
            filterSelectedOptions
            onChange={(event, value) => onSelectedSquadGroupChange(value)}
            renderInput={(params) => <TextField {...params} size="small" label="Select squad group" />}
          />
        ) : (
          <CircularProgress />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleModalClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAssignEmployeesToSquad} color="primary" disabled={isDisabled}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignToSquadModal;
