import React, { createContext, useContext, useState } from 'react';
import { AsyncStatus } from 'src/hooks/useAsync';
import { EmployeeService } from 'src/services/EmployeeService';
import { SquadGroup, SquadGroupService } from 'src/services/SquadGroupService';
import { TagService } from 'src/services/TagService';
import { SquadsContext } from 'src/utilities/SquadsContext';
import useSWR from 'swr';
import { sortEmployees } from '../entityList/sortEmployees';
import { ReducedEmployee } from 'src/Models/EmployeeModels';
import { TagCategory } from 'src/Models/TagModels';

interface EntityContextProps {
  employee: any | null;
  setEmployee: React.Dispatch<React.SetStateAction<any | null>>;
  group: SquadGroup | null;
  setGroup: React.Dispatch<React.SetStateAction<SquadGroup | null>>;
  getEmployees: (silent?: boolean) => Promise<any>;
  employeeStatus: AsyncStatus;
  employees: ReducedEmployee[] | null | ReducedEmployee[] | null;
  groups: SquadGroup[] | null;
  groupStatus: AsyncStatus;
  getGroups: (silent?: boolean) => Promise<void>;
  isFocused: boolean;
  setFocus: (isFocused: boolean) => void;
  tags: TagCategory[] | null;
  getTags: (silent?: boolean) => Promise<any>;
  isTagsLoading: boolean;
  isUpdated: boolean;
  setIsUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

const EntityContext = createContext<EntityContextProps>({
  employee: null,
  setEmployee: () => { },
  group: null,
  setGroup: () => { },
  getEmployees: async () => { },
  employeeStatus: 'idle',
  employees: null,
  groups: null,
  groupStatus: 'idle',
  getGroups: async () => { },
  isFocused: false,
  setFocus: (isFocused: boolean) => { },
  tags: null,
  getTags: async () => { },
  isTagsLoading: false,
  isUpdated: false,
  setIsUpdated: () => { },
});

const employeeService = new EmployeeService();
const squadGroupService = new SquadGroupService();
const tagService = new TagService();

const getStatus = (isLoading: boolean, error: boolean): AsyncStatus => {
  if (isLoading) return 'pending';
  if (error) return 'error';
  return 'success';
};

export const EntityProvider: React.FC = ({ children }) => {
  const [employee, setEmployee] = useState<ReducedEmployee | null>(null);
  const [group, setGroup] = useState<SquadGroup | null>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const { squad } = useContext(SquadsContext);

  const getEmployeeData = async () => employeeService.getAllEmployeesForSquad(squad.squadId);
  const getGroupData = async () => squadGroupService.getSquadGroupsBySquadId(squad.squadId);
  const getTagData = async () => await tagService.getAllCategoriesAndTags();

  const { data: employees, isLoading: employeeLoading, mutate: getEmployees, error: employeeError } = useSWR('employees', getEmployeeData);
  const { data: groups, mutate: getGroups, isLoading: groupLoading, error: groupError } = useSWR('groups', getGroupData);
  const { data: tags, mutate: getTags, isLoading: isTagsLoading } = useSWR('tags', getTagData);

  return (
    <EntityContext.Provider
      value={{
        employee,
        setEmployee,
        employeeStatus: getStatus(employeeLoading, !!employeeError),
        group,
        setGroup,
        employees: sortEmployees(employees ?? []),
        groups: groups ?? null,
        getEmployees: () => getEmployees(),
        getGroups: async () => {
          getGroups();
        },
        groupStatus: getStatus(groupLoading, groupError),
        isFocused,
        setFocus: setIsFocused,
        tags: tags ?? [],
        getTags: () => getTags(),
        isTagsLoading,
        isUpdated,
        setIsUpdated,
      }}>
      {children}
    </EntityContext.Provider>
  );
};

export const useEntityContext = () => {
  return useContext(EntityContext);
};
