import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography, accordionSummaryClasses } from '@mui/material';
import React, { useState } from 'react';
import Carousel from 'react-carousel-mui';

import QuickFilters from '../QuickFilters';
import ActiveSquadBarChart from './ActiveSquadBarChart';
import CertificationBarChart from './CertificationBarChart';
import GlobalDateFilter from './GlobalDateFilter';
import LearningPathBarChart from './LearningPathBarChart';
import TopFiveTags from './TopFiveTags';

const ChartsArea: React.FC = () => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const chartItems = [
    <Box key="ActiveSquadBarChart" flex="1" marginX={1}>
      <ActiveSquadBarChart />
    </Box>,
    <Box key="LearningPathBarChart" flex="1" marginX={1}>
      <LearningPathBarChart />
    </Box>,
    <Box key="CertificationBarChart" flex="1" marginX={1}>
      <CertificationBarChart />
    </Box>,
    <Box key="TopFiveTags" flex="1" marginX={1}>
      <TopFiveTags />
    </Box>,
  ];

  return (
    <Accordion elevation={0} expanded={expanded} onChange={() => setExpanded(!expanded)} defaultExpanded>
      <AccordionSummary
        sx={{
          pointerEvents: 'none',
          [`&.${accordionSummaryClasses.root}`]: {
            minHeight: '12px',
            height: '12px',
            paddingX: 0,
          },
          [`& .${accordionSummaryClasses.content}`]: {
            display: 'flex',
            justifyContent: 'flex-end',
            flexGrow: 1,
            marginY: 0,
          },
        }}>
        <Box sx={{ pointerEvents: 'auto' }} onClick={() => setExpanded(!expanded)}>
          <Typography variant="subtitle2" sx={{ textDecoration: 'underline' }}>
            {expanded ? 'Hide' : 'Show'} dashboard view
          </Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ paddingX: 0 }}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} md={4}>
            <GlobalDateFilter />
          </Grid>
          <Grid item container minHeight="312px" display="flex" flexDirection="row" spacing={2} width="200%">
            <Grid item xs={12} md={2}>
              <QuickFilters />
            </Grid>
            <Grid item xs={12} md={10}>
              <div style={{ marginLeft: '125px' }}>
                <Carousel
                  items={chartItems}
                  itemsPerPage={{
                    xs: 2,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                    tablet: 2,
                  }}
                  itemRenderer={(item) => <Grid>{item}</Grid>}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ChartsArea;
