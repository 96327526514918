import FlagIcon from '@mui/icons-material/Flag';
import { Checkbox, IconButton, TableRow, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DashboardEmployee, EmployeeDetails } from 'src/Models/EmployeeModels';
import useIsMounted from 'src/hooks/useIsMounted';
import { EmployeeService } from 'src/services/EmployeeService';
import { SquadsContext } from 'src/utilities/SquadsContext';
import { entelectBlue } from 'src/utilities/customColors';

import EmployeeSquadModal from './EmployeeSquadModal';
import { NarrowTableCell } from './NarrowTableCell';
import Sentiment from './Sentiment';

const BlackTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

interface Props {
  employee: DashboardEmployee;
  handleCheckBoxChecked?: (value: DashboardEmployee) => void;
  selectedEmployees?: DashboardEmployee[];
}

const employeeService = new EmployeeService();

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const EmployeeOverviewTableRow: React.FC<Props> = ({ employee, handleCheckBoxChecked, selectedEmployees }: Props) => {
  const isMounted = useIsMounted();
  const [employeeDetails, setEmployeeDetails] = useState<EmployeeDetails>();
  const { squad } = React.useContext(SquadsContext);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  useEffect(() => {
    employeeService.getSquadsByEmployeeId(employee.employeeId, parseInt(squad.squadId)).then((data) => {
      isMounted() && setEmployeeDetails(data);
    });
  }, [employee.employeeId]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <StyledTableRow key={employee.employeeId} sx={{ cursor: 'pointer', height: '50px' }}>
      <NarrowTableCell sx={{ width: '10px' }}>
        <Checkbox
          {...label}
          checked={selectedEmployees?.includes(employee)}
          onChange={() => handleCheckBoxChecked && handleCheckBoxChecked(employee)}
        />
      </NarrowTableCell>
      <NarrowTableCell sx={{ width: '10px' }}>{employee.sentiment && <Sentiment sentiment={employee.sentiment} />}</NarrowTableCell>
      <NarrowTableCell sx={{ width: '50px' }}>
        {employee.followUp ? (
          <IconButton>
            <FlagIcon color="error" />
          </IconButton>
        ) : (
          ''
        )}
      </NarrowTableCell>
      <NarrowTableCell align="left" sx={{ width: '250px' }}>
        <Link to={`/capture-feedback/${employee.employeeId}`}>{employee.fullName}</Link>
      </NarrowTableCell>
      <NarrowTableCell align="left" sx={{ width: '250px' }}>
        {employee.jobTitle.title}
      </NarrowTableCell>
      <NarrowTableCell align="left" sx={{ width: '150px' }}>
        {employeeDetails?.currentEmployeeGroups && employeeDetails?.currentEmployeeGroups?.length > 0 && (
          <>
            {employeeDetails && employeeDetails.currentEmployeeGroups?.length === 1 ? (
              <BlackTooltip
                title={
                  <div>
                    <i>Select to view group leader(s)</i>
                  </div>
                }>
                <Typography
                  fontWeight={400}
                  sx={{ color: entelectBlue, ':hover': { cursor: 'pointer', textDecoration: 'underline' }, fontSize: '12px' }}
                  onClick={openModal}>
                  {employeeDetails.currentEmployeeGroups.map((group) => (
                    <div key={group.groupName}>{group.groupName}</div>
                  ))}
                </Typography>
              </BlackTooltip>
            ) : (
              <BlackTooltip
                title={
                  <div>
                    Squads:
                    {employeeDetails && employeeDetails.currentEmployeeGroups.map((group) => <div key={group.groupName}>{group.groupName}</div>)}
                    <i>Select to view group leaders</i>
                  </div>
                }>
                <Typography
                  fontWeight={400}
                  sx={{ color: entelectBlue, ':hover': { cursor: 'pointer', textDecoration: 'underline' }, fontSize: '12px' }}
                  onClick={openModal}>
                  {employeeDetails && employeeDetails.currentEmployeeGroups?.length} squads
                </Typography>
              </BlackTooltip>
            )}
          </>
        )}
      </NarrowTableCell>
      <NarrowTableCell align="left" sx={{ width: '150px' }}>
        {employee.daysOnSquad.timeOnTFOString && employee.daysOnSquad.timeOnTFOString.length > 0 ? employee.daysOnSquad.timeOnTFOString : ''}
      </NarrowTableCell>
      <NarrowTableCell align="left" sx={{ width: '150px' }}>
        {employee.latestFeedbackCategory}
      </NarrowTableCell>
      <NarrowTableCell align="left" sx={{ width: '150px' }}>
        {employee.status.statusName}
      </NarrowTableCell>
      <EmployeeSquadModal isOpen={isModalOpen} onClose={closeModal} employeeName={employee.fullName} employeeDetails={employeeDetails} />
    </StyledTableRow>
  );
};

export default EmployeeOverviewTableRow;
