import { Accordion, AccordionProps } from "@mui/material";
import React from "react";

const UpsideDownAccordion: React.FC<AccordionProps> = ({
    children,
    expanded,
    onChange
}) => {
    return (
        <Accordion
            disableGutters
            elevation={0}
            expanded={expanded}
            onChange={onChange}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: '4px'
            }}
        >
            {children}
        </Accordion>
    );
}

export default UpsideDownAccordion;