import React, { useContext, useEffect, useState } from 'react';
import { Tag } from 'src/Models/TagModels';
import { EmployeeService } from 'src/services/EmployeeService';
import { SquadsContext } from 'src/utilities/SquadsContext';

import CustomInputSearchBar from '../activeEmployeesDashboard/CustomInputSearchBar';
import { useEntityContext } from '../feedbackComponent/context/EntityContext';
import { useClientManagementDashboardContext } from './context/ClientManagementDashboardContext';

const ClientManagementTableAreaTagFilter: React.FC = () => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [tagObjects, setTagObjects] = useState<Tag[]>([]);
  const { tags } = useEntityContext();
  const employeeService = new EmployeeService();
  const { squad } = useContext(SquadsContext);
  const { setEmployees, setIsLoading, getAllIncommingEmployees } = useClientManagementDashboardContext();

  useEffect(() => {
    if (tags) {
      const tagObjects = tags.flatMap((tagGroup) => tagGroup.tags);
      setTagObjects(tagObjects);
      const tagNames = tagObjects.map((tag) => tag.tagName);
      setSuggestions(tagNames);
    }
  }, [tags]);

  const handleSearch = (query: string) => {
    const selectedTag = tagObjects.filter((tag) => tag.tagName === query);
    handleEmployeeFilter(selectedTag[0]);
  };

  const handleEmployeeFilter = async (tag: Tag) => {
    setIsLoading(true);
    if (tag) {
      const response = await employeeService.GetIncommingDashboardEmployeeQuickFilter(squad.squadId, tag.tagId.toString());
      if (response) {
        setEmployees(response);
        setIsLoading(false);
      } else {
        getAllIncommingEmployees();
        setIsLoading(false);
      }
    } else {
      getAllIncommingEmployees();
    }
  };

  return (
    <div>
      <CustomInputSearchBar suggestions={suggestions} onSearch={handleSearch} />
    </div>
  );
};

export default ClientManagementTableAreaTagFilter;
