import {
  Tag,
  EmployeeTag,
  CreateEmployeeTagModel,
  CreateTagModel,
  CreateTagCategory,
  TagsGroupedByCategoryId,
  CreateSubTagModel,
  SubTag,
  TagCategory,
  CreateLinkedTagModel,
  TagGraphRequestModel,
  TagDashboardGraphModel,
} from 'src/Models/TagModels';

import { BaseService } from './BaseService';
import { Employee } from './FeedbackType';

export class TagService extends BaseService {
  constructor() {
    super('Tags');
  }

  async getAllTags(): Promise<Tag[]> {
    return (await this.httpService.get(``)).data;
  }

  async getAllEmployeeTags(squadId: number): Promise<EmployeeTag[]> {
    return (await this.httpService.get(`/employee-tags/${squadId}`)).data;
  }

  async createEmployeeTag(model: CreateEmployeeTagModel): Promise<any> {
    return (await this.httpService.post('/employee-tags/add', model)).data;
  }

  async createTag(model: CreateTagModel): Promise<any> {
    return (await this.httpService.post('/tags/add', model)).data;
  }

  async deleteTag(tagId: number): Promise<any> {
    return await this.httpService.delete(`/tags/delete/${tagId}`);
  }

  async deleteEmployeeTag(employeeTagId: number): Promise<any> {
    return await this.httpService.delete(`/employee-tags/delete/${employeeTagId}`);
  }

  async getEmployeesByTags(tags: string, squadId: string | number): Promise<Employee[]> {
    return (await this.httpService.get(`/employees/find-by-tag/${squadId}`, { params: { tags } })).data;
  }

  async updateTag(tag: Tag, tagId: number): Promise<any> {
    return (await this.httpService.patch(`/tag/update/${tagId}`, tag)).data;
  }

  async updateEmployeeTag(employeeTag: EmployeeTag, employeeTagId: number): Promise<any> {
    return (await this.httpService.patch(`/employee-tag/update/${employeeTagId}`, employeeTag)).data;
  }

  async getTagsByEmployee(employeeId: number): Promise<Tag[]> {
    return (await this.httpService.get(`/tags/${employeeId}`)).data;
  }

  async createTagCategory(model: CreateTagCategory): Promise<any> {
    return (await this.httpService.post(`/tag-category/add`, model)).data;
  }

  async getTagsByCategoryId(tagCategoryId: number): Promise<Tag[]> {
    return (await this.httpService.get(`/tags-category/${tagCategoryId}`)).data;
  }
  async getTagsGroupedByCategory(): Promise<TagsGroupedByCategoryId[]> {
    return (await this.httpService.get(`/tags/groupedByCategory`)).data;
  }

  async addSubTags(createSubTagModel: CreateSubTagModel, tagId: string | number): Promise<any> {
    return (await this.httpService.post(`/sub-tags/add/${tagId}`, createSubTagModel)).data;
  }

  async getSubTagsByTagId(tagId: number): Promise<SubTag[]> {
    return (await this.httpService.get(`/sub-tags/${tagId}`)).data;
  }

  async getAllSubTags(): Promise<SubTag[]> {
    return (await this.httpService.get(`/sub-tags`)).data;
  }

  async getAllCategoriesAndTags(): Promise<TagCategory[]> {
    return (await this.httpService.get(`/categories/tags`)).data;
  }

  async getCategoriesByEmployeeId(employeeId: number): Promise<TagCategory[]> {
    return (await this.httpService.get(`/categories/${employeeId}`)).data;
  }

  async removeSubTag(subTagId: number): Promise<number> {
    return (await this.httpService.delete(`/subtag/delete/${subTagId}`)).data;
  }

  async updateTagCategory(model: TagCategory): Promise<number> {
    return (await this.httpService.patch(`/tag-category/update`, model)).data;
  }

  async deleteTagCategory(tagCategoryId: number | string): Promise<number> {
    return (await this.httpService.delete(`/tag-category/delete/${tagCategoryId}`)).data;
  }

  async hardDeleteTag(tagId: number | string): Promise<number> {
    return (await this.httpService.delete(`/tag/hard-delete/${tagId}`)).data;
  }

  async addLinkTag(model: CreateLinkedTagModel): Promise<any> {
    return (await this.httpService.post(`/linkTag/add`, model)).data;
  }

  async getTagAndLinkedTags(tagId: number): Promise<Tag[]> {
    return (await this.httpService.get(`/tag/linkedTags/all/${tagId}`)).data;
  }

  async removeLinkedTag(linkTagId: number): Promise<any> {
    return (await this.httpService.delete(`/linkTag/delete/${linkTagId}`)).data;
  }

  async getTopTagsGraphData(tagGraphRequestModel: TagGraphRequestModel): Promise<TagDashboardGraphModel[]> {
    const params = new URLSearchParams();

    if (tagGraphRequestModel.tagId) {
      params.append('tagId', tagGraphRequestModel.tagId.toString());
    }

    if (tagGraphRequestModel.dateFrom) {
      params.append('dateFrom', new Date(tagGraphRequestModel.dateFrom).toISOString());
    }

    if (tagGraphRequestModel.dateTo) {
      params.append('dateTo', new Date(tagGraphRequestModel.dateTo).toISOString());
    }

    const queryString = params.toString();
    const url = queryString ? `/tagchart/top?${queryString}` : '/tagchart/top';

    const response = await this.httpService.get(url);
    return response.data;
  }
}
