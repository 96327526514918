import { useAccount, useMsal } from '@azure/msal-react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Avatar, Box, Button, Container, Divider, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SquadsContext } from 'src/utilities/SquadsContext';
import { useTaskContext } from 'src/utilities/TaskContext';

import { LoggedInUserContext } from '../../utilities/LoggedInUserContext';
import { SquadLeaderContext } from '../../utilities/SquadLeaderContext';
import '../AppBar.css';
import { CustomNavLink } from '../CustomNavLink';
import CustomBadge from '../shared/CustomBadge';
import ResponsiveAppBarHeaderProfile from './ResponsiveAppBarHeaderProfile';

interface ResponsiveAppBarProps {
  visited: Set<string>;
}

const ResponsiveAppBar: React.FC<ResponsiveAppBarProps> = ({ visited }) => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElManage, setAnchorElManage] = useState<null | HTMLElement>(null);

  const { loggedInUserFullName } = React.useContext(LoggedInUserContext);
  const { squadLeader, activeSquadLeader } = React.useContext(SquadLeaderContext);
  const { squad } = React.useContext(SquadsContext);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [isTenantManager, setIsTenantManager] = React.useState(false);

  const {
    numberOfTasks,
    getOutstandingTasks
  } = useTaskContext();


  useEffect(() => {
    setIsTenantManager(activeSquadLeader?.user?.isTenantManager ?? false);
  }, [activeSquadLeader]);

  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    getOutstandingTasks();
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenManageMenu = (event: { currentTarget: React.SetStateAction<HTMLElement | null> }) => {
    setAnchorElManage(event.currentTarget);
  };

  const handleCloseNavMenu = (key: any) => {
    setAnchorElNav(null);
  };

  const handleCloseManageMenu = () => {
    setAnchorElManage(null);
  };

  const navigateHome = () => {
    setAnchorElUser(null);
    navigate('/');
  };

  const navigateToFeedback = () => {
    setAnchorElUser(null);
    navigate('/capture-feedback');
  };

  const navigateToOverview = () => {
    setAnchorElUser(null);
    navigate('/overview');
  };

  const navigateToManageCategories = () => {
    setAnchorElManage(null);
    navigate('/manage-categories');
  };

  const navigateToManageCertificates = () => {
    setAnchorElManage(null);
    navigate('/manage-certificates');
  };

  const navigateToManageTags = () => {
    setAnchorElManage(null);
    navigate('/manage-tags');
  };

  const navigateToManageTasks = () => {
    setAnchorElManage(null);
    navigate('/manage-tasks');
  };

  const navigateToManageUsers = () => {
    setAnchorElManage(null);
    navigate('/manage-users');
  };

  const navigateToManageJobTitles = () => {
    setAnchorElManage(null);
    navigate('/manage-job-titles');
  };

  const navigateToManageEmployeeSquadUsers = () => {
    setAnchorElManage(null);
    navigate('/manage-squad-employees');
  };

  const navigateToManageSquads = () => {
    setAnchorElManage(null);
    navigate('/manage-squads');
  };

  const navigateToManageSettings = () => {
    setAnchorElUser(null);
    navigate('/manage-settings');
  };

  const navigateToManageGroups = () => {
    setAnchorElManage(null);
    navigate('/manage-groups');
  };

  const navigateToBulkFeedbackUpload = () => {
    setAnchorElManage(null);
    navigate('/bulk-upload-feedback');
  };

  const navigateToBulkEmployeeUpload = () => {
    setAnchorElManage(null);
    navigate('/bulk-upload-employee');
  };

  const signOutClickHandler = () => {
    if (!account) {
      throw Error('No active account! Verify a user has been signed in.');
    }

    const logoutRequest = {
      account,
    };

    instance.logout(logoutRequest);
  };

  return (
    <AppBar className="app-bar" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Box id="branding" onClick={navigateHome}>
            <IconButton>
              <img src="/images/header/entelect-text.png" alt="logo" className="brand-name" width="98" height="35" />
            </IconButton>
            <IconButton>
              <Avatar src="/images/header/entelect-logo.png" className="brand-image-logo" />
            </IconButton>
          </Box>
          <Typography variant="h6" noWrap component="div" sx={{ mr: 2, display: { xs: 'flex', md: 'flex' } }} />
          <Box id="mobile-navigation" sx={{ flexGrow: 29, display: { xs: 'flex', lg: 'none' } }}>
            <IconButton
              className="menu-icon"
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ position: 'fixed', right: '20px', top: '6px' }}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}>
              <MenuItem onClick={navigateHome}>
                <Typography textAlign="center">Dashboard</Typography>
              </MenuItem>
              <MenuItem onClick={navigateToFeedback}>
                <Typography textAlign="center">Capture Feedback</Typography>
              </MenuItem>
              <MenuItem onClick={navigateToOverview}>
                <Typography textAlign="center">Overview</Typography>
              </MenuItem>
              <MenuItem onClick={navigateToManageTasks}>
                <Typography textAlign="center">Tasks</Typography>
              </MenuItem>
              <MenuItem onClick={navigateToManageCategories}>
                <Typography textAlign="center">Manage Categories</Typography>
              </MenuItem>

              {(activeSquadLeader?.isAdmin || activeSquadLeader?.isSuperUser) && (
                <MenuItem onClick={navigateToManageTags}>
                  <Typography textAlign="center">Manage Tags</Typography>
                </MenuItem>
              )}

              <MenuItem onClick={navigateToManageEmployeeSquadUsers}>
                <Typography textAlign="center">Manage Employee/Squad Users</Typography>
              </MenuItem>

              <MenuItem onClick={navigateToManageGroups}>
                <Typography textAlign="center">Manage Groups</Typography>
              </MenuItem>
              {isTenantManager && (
                <MenuItem onClick={navigateToManageJobTitles}>
                  <Typography textAlign="center">Manage Job Titles</Typography>
                </MenuItem>
              )}
              <MenuItem onClick={navigateToManageSquads}>
                <Typography textAlign="center">Manage Tenants</Typography>
              </MenuItem>

              <Divider variant="middle" />
              <MenuItem onClick={navigateToManageUsers}>
                <Typography textAlign="center">Manage Users</Typography>
              </MenuItem>
              <MenuItem onClick={navigateToManageSettings}>
                <Typography textAlign="center">Settings</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} />
          <Box id="desktop-navigation" flexGrow={1} sx={{ display: { xs: 'none', lg: 'flex' } }}>
            <CustomNavLink className="nav-button" to={'/'}>
              Dashboard
            </CustomNavLink>
            <CustomNavLink className="nav-button" to={'/capture-feedback'}>
              Capture Feedback
            </CustomNavLink>

            {(activeSquadLeader?.isAdmin || activeSquadLeader?.isSuperUser) && (
              <CustomNavLink className="nav-button" to={'/overview'}>
                Overview
              </CustomNavLink>
            )}

            <CustomNavLink className="nav-button" to={'/manage-tasks'}>
              <CustomBadge
                invisible={numberOfTasks === 0}
                badgeContent={numberOfTasks}
              >
                Tasks
              </CustomBadge>
            </CustomNavLink>

            <div>
              <Button
                onClick={handleOpenManageMenu}
                sx={{
                  color: 'white',
                  textTransform: 'uppercase',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '500',
                  fontSize: '0.875rem',
                  lineHeight: '1.75',
                  letterSpacing: '0.02857em',
                  padding: '1.1rem 1.1rem',
                }}
                endIcon={
                  !Boolean(anchorElManage) ? (
                    <ExpandMore sx={{ verticalAlign: 'bottom', color: 'white' }} />
                  ) : (
                    <ExpandLess sx={{ verticalAlign: 'bottom', color: 'white' }} />
                  )
                }>
                Manage App
              </Button>
              <Menu
                sx={{ mt: '45px' }}
                id="manage-app-menu"
                anchorEl={anchorElManage}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElManage)}
                onClose={handleCloseManageMenu}>

                {activeSquadLeader?.user?.isTenantManager && (
                  <MenuItem onClick={navigateToManageSquads}>
                    <Typography textAlign="center">Tenants</Typography>
                  </MenuItem>
                )}
                {!activeSquadLeader?.user?.isTenantManager && activeSquadLeader?.isSuperUser && (
                  <MenuItem onClick={navigateToManageUsers}>
                    <Typography textAlign="center">{squad.shortName} Admin users</Typography>
                  </MenuItem>
                )}
                {activeSquadLeader && (
                  <MenuItem onClick={navigateToManageEmployeeSquadUsers}>
                    <Typography textAlign="center">Squads and employees</Typography>
                  </MenuItem>
                )}
                {(activeSquadLeader?.isAdmin || activeSquadLeader?.isSuperUser) && (
                  <MenuItem onClick={navigateToManageCategories}>
                    <Typography textAlign="center">Categories</Typography>
                  </MenuItem>
                )}
                {(activeSquadLeader?.isAdmin || activeSquadLeader?.isSuperUser) && (
                  <MenuItem onClick={navigateToManageCertificates}>
                    <Typography textAlign="center">Certifications</Typography>
                  </MenuItem>
                )}
                {(activeSquadLeader?.isAdmin || activeSquadLeader?.isSuperUser) && (
                  <MenuItem onClick={navigateToManageTags}>
                    <Typography textAlign="center">Tags</Typography>
                  </MenuItem>
                )}
                {(activeSquadLeader?.user?.isTenantManager || activeSquadLeader?.isSuperUser) && (
                  <MenuItem onClick={navigateToManageJobTitles}>
                    <Typography textAlign="center">Job Titles</Typography>
                  </MenuItem>
                )}
                {activeSquadLeader && (
                  <MenuItem onClick={navigateToBulkFeedbackUpload}>
                    <Typography textAlign="center"> Bulk Feedback Upload</Typography>
                  </MenuItem>
                )}
                {activeSquadLeader && (
                  <MenuItem onClick={navigateToBulkEmployeeUpload}>
                    <Typography textAlign="center"> Bulk Employee Upload</Typography>
                  </MenuItem>
                )}
              </Menu>
            </div>
          </Box>

          <ResponsiveAppBarHeaderProfile
            loggedInUserFullName={loggedInUserFullName}
            handleOpenUserMenu={handleOpenUserMenu}
            squadLeader={squadLeader}
            visited={visited}
            anchorElUser={anchorElUser}
            setAnchorElUser={setAnchorElUser}
            navigateToManageSettings={navigateToManageSettings}
            signOutClickHandler={signOutClickHandler}
            activeSquadLeader={activeSquadLeader}
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
