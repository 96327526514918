import { AccordionSummary, AccordionSummaryProps, accordionSummaryClasses } from "@mui/material";
import React from "react";

const UpsideDownAccordionSummary: React.FC<AccordionSummaryProps> = ({
    children,
}) => {
    return (
        <AccordionSummary
            aria-controls="accordion-summary"
            id="accordion-summary"
            sx={{
                order: 2,
                minHeight: '1.5rem',
                paddingX: 0,

                [`& .${accordionSummaryClasses.content}`]: {
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexGrow: 1,
                    marginY: 0,
                }
            }}
        >
            {children}
        </AccordionSummary>
    );
}

export default UpsideDownAccordionSummary;