import { DashboardEmployee } from 'src/Models/EmployeeModels';
import { IncommingEmployeeStatusModel } from 'src/Models/EmployeeStatusModels';

import { BaseService } from './BaseService';

export class EmployeeStatusService extends BaseService {
  constructor() {
    super('EmployeeStatus');
  }

  async getStatusBySquadId(squadId: string): Promise<IncommingEmployeeStatusModel[]> {
    return (await this.httpService.get(`/status/incomming/${squadId}`)).data;
  }

  async getIncommingEmployeesByStatus(squadId: string, statusName: string): Promise<DashboardEmployee[]> {
    return (await this.httpService.get(`/status/employees/${squadId}/${statusName}`)).data;
  }
}
