import { AxiosResponse } from 'axios';
import {
  DashboardLearningPathDTO,
  EmployeeLearningPathDTO,
  LearningPathDTO,
  StartEmployeeLearningPathDTO,
  UpdateEmployeeLearningPhaseDTO,
} from 'src/Models/LearninpathModels';

import { BaseService } from './BaseService';

export class LearningPathFallBackService extends BaseService {
  constructor() {
    super('LearningPaths');
  }

  async updateEmployeePathExtended(squadId: string, payload: UpdateEmployeeLearningPhaseDTO): Promise<AxiosResponse> {
    return await this.httpService.patch(`/path/${squadId}`, payload);
  }

  async getLearningPathConfigs(): Promise<LearningPathDTO[]> {
    return (await this.httpService.get('/config')).data;
  }

  async getEmployeeLearningPaths(employeeId: number): Promise<EmployeeLearningPathDTO[]> {
    return (await this.httpService.get(`/${employeeId}`)).data;
  }

  async startEmployeeLearningPath(model: StartEmployeeLearningPathDTO): Promise<any> {
    return (await this.httpService.post('/path/new', model)).data;
  }

  async getDashboardLearningPaths(startDate: Date | null, endDate: Date | null): Promise<DashboardLearningPathDTO[]> {
    return (
      await this.httpService.get(
        `/dashboard?startDate=${startDate ? new Date(startDate).toISOString() : ''}&endDate=${endDate ? new Date(endDate).toISOString() : ''}`,
      )
    ).data;
  }
}
