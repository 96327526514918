import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DashboardLearningPathDTO } from 'src/Models/LearninpathModels';
import { EmployeeService } from 'src/services/EmployeeService';
import { LearningPathFallBackService } from 'src/services/LearningPathFallBackService';
import { SquadsContext } from 'src/utilities/SquadsContext';

import ChartHeading from '../../dashboardShared/ChartHeading';
import { useDashboardContext } from '../context/DashboardContext';
import { useFilterContext } from '../context/FilterContext';
import LearningPathChartOverview from './LearningPathChartOverview';

const learningPathService = new LearningPathFallBackService();
const employeeService = new EmployeeService();

const LearningPathBarChart: React.FC = () => {
  const [learningPaths, setLearningPaths] = useState<DashboardLearningPathDTO[] | undefined>();

  const { squad } = React.useContext(SquadsContext);

  const { setEmployees, setIsLoading } = useDashboardContext();

  const { learningPathFilterRequest, startDate, endDate } = useFilterContext();

  const getLearningPathData = async () => {
    const response = await learningPathService.getDashboardLearningPaths(startDate, endDate);
    setLearningPaths(response);
  };

  const getFiltredEmployees = async () => {
    const response = await employeeService.getActiveDashboardEmployees(+squad.squadId, learningPathFilterRequest);
    setEmployees(response);
  };

  useEffect(() => {
    setIsLoading(true);
    getLearningPathData();
    setIsLoading(false);
  }, [startDate, endDate]);

  useEffect(() => {
    getFiltredEmployees();
  }, [learningPathFilterRequest]);

  return (
    <>
      <ChartHeading>{'Learning path overview'}</ChartHeading>
      {learningPaths ? (
        <Box display="flex" flexDirection="column" width="100%">
          <LearningPathChartOverview dashboardLearningPathData={learningPaths} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" width="100%" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default LearningPathBarChart;
