import { Grid } from '@mui/material';
import React from 'react';

import StyledTab from '../shared/StyledTab';
import StyledTabList from '../shared/StyledTabList';

interface DashboardTabsProps {
  setSelectedTab: (selectedTab: string) => void;
}

const DashboardTabs: React.FC<DashboardTabsProps> = ({ setSelectedTab }) => {
  return (
    <Grid container id="dashboard-tabs">
      <Grid item xs={12} md={12} lg={2}>
        <StyledTabList sx={{ width: '150%' }} TabIndicatorProps={{ style: { display: 'none' } }}>
          <StyledTab
            sx={{
              width: '100%',
              color: 'gray',
              '&.Mui-selected': {
                color: 'entelectBlue',
              },
            }}
            label="Active Employees"
            value="active-employees"
            onClick={() => setSelectedTab('active-employees')}
          />
          <StyledTab
            sx={{
              width: '100%',
              color: 'gray',
              '&.Mui-selected': {
                color: 'entelectBlue',
              },
            }}
            label="Incoming TFO"
            value="client-management"
            onClick={() => setSelectedTab('client-management')}
          />
        </StyledTabList>
      </Grid>
    </Grid>
  );
};

export default DashboardTabs;
