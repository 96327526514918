import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import { isEmpty, isEqual } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FeedbackTags } from 'src/components/feedbackComponent/FeedbackTags';
import { useGetProfilePicture } from 'src/hooks/useGetProfilePicture';
import useIsMobile from 'src/hooks/useIsMobile';
import { entelectBlue, grey50 } from 'src/utilities/customColors';
import useSWR from 'swr';

import { EmployeeService } from '../../../services/EmployeeService';
import { SquadsContext } from '../../../utilities/SquadsContext';
import { EntityProvider, useEntityContext } from '../../feedbackComponent/context/EntityContext';
import EmployeeContactDetails from './EmployeeContactDetails';
import EmployeeProfileHeader from './EmployeeHeader';
import { EmployeeTeamDetails } from './EmployeeTeamDetails';
import EmployeeProjectTimeline from './ProjectTimeline';
import { RelatedEmployees } from './RelatedEmployees';

interface EmployeeProfileProps { }

const employeeService = new EmployeeService();

const EmployeeProfilePage: React.FC<EmployeeProfileProps> = () => {
  const { squad } = useContext(SquadsContext);
  const { setEmployee, employees, employee, getEmployees } = useEntityContext();

  useEffect(() => {
    if (squad.squadId) {
      getEmployees();
    }
  }, [squad.squadId]);

  const { entelectId } = useParams();
  const selectedEntelectEmployee = entelectId?.toString() || '-1';

  useEffect(() => {
    if (isEmpty(selectedEntelectEmployee)) return;
    const newEmployee = employees?.find((employee) => employee.employeeId === parseInt(selectedEntelectEmployee));
    if (newEmployee && !isEqual(newEmployee, employee)) {
      setEmployee(newEmployee);
    }
  }, [employees, selectedEntelectEmployee]);

  const emailAddress = employee?.emailAddress || '';
  const employeeId: number = employee?.employeeId || -1;
  const fullName = employee?.fullName || '';
  const jobTitle = employee?.jobTitle;
  const isDeleted = employee?.isDeleted || false;

  const { profilePictureImgSrc } = useGetProfilePicture(emailAddress, 240);

  const { data: telephoneNumber } = useSWR(emailAddress ? `telephoneNumber ${emailAddress}` : undefined, async () =>
    employeeService.getMobileNumber(emailAddress),
  );

  const isMobile = useIsMobile();
  const { data: employeeDetails } = useSWR(employeeId > -1 ? ['employeeDetails', employeeId] : undefined, async () =>
    employeeService.getSquadsByEmployeeId(employeeId, parseInt(squad.squadId)),
  );

  const EmployeeTag = () => (
    <>
      <FeedbackTags employee={employee} refreshData={refreshData} showImported={true} clickable={false} />
    </>
  );

  const [refreshData, setRefreshData] = React.useState(true);

  useEffect(() => {
    setRefreshData(!refreshData);
  }, [employee]);

  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Button
        onClick={navigateBack}
        startIcon={<ArrowBackIcon />}
        sx={{
          color: { entelectBlue },
          margin: '0 10%',
          justifyContent: 'start',
        }}>
        Back
      </Button>
      <Box
        sx={{
          backgroundColor: 'white',
          width: '80%',
          maxWidth: '1100px',
          margin: '0 auto',
          padding: '20px',
          borderRadius: '15px',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
        }}>
        <Grid
          id="title"
          style={{
            backgroundColor: grey50,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 14px 0 20px',
            height: 70,
            width: '100%',
            borderRadius: '15px',
          }}>
          <Typography variant="h6" component="div" align="center" sx={{ color: '#4A5568' }}>
            <EmployeeProfileHeader fullName={fullName} jobTitle={jobTitle ?? ' '} isActive={!isDeleted} />
          </Typography>
        </Grid>

        <Grid container direction="row" justifyContent={isMobile ? 'center' : 'flex-start'}>
          <Grid id="LeftContent" item xs={12} md={6}>
            <Grid container alignItems="center">
              <Grid id="Avatar" item xs={12} sm={12} sx={{ margin: '24px' }} md={4}>
                <Avatar
                  variant="square"
                  src={profilePictureImgSrc}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: 200,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={8} md={6}>
                <Typography variant="h6" color={'#5E5E5E'}>
                  Contact Details
                </Typography>
                <EmployeeContactDetails emailAddress={emailAddress} telephoneNumber={telephoneNumber} />
                <Typography variant="h6" color={'#5E5E5E'} fontSize={'18px'}>
                  Current Team (Tenant) and Group Allocation
                </Typography>
                <EmployeeTeamDetails employeeName={fullName} employeeDetails={employeeDetails} />
              </Grid>
            </Grid>
            <Grid id="TagsAndRelated" item xs={12}>
              <hr color={grey50} style={{ height: '1px', width: '95%' }} />
              {employee && <EmployeeTag />}
              <RelatedEmployees employeeId={employeeId} />
              <hr color={grey50} style={{ height: '1px', width: '95%' }} />
            </Grid>
          </Grid>
          <Grid id="ProjectTimeline" item xs={12} md={6}>
            <Typography variant="h6" sx={{ color: '#4A5568' }}>
              Project Timeline
            </Typography>
            <EmployeeProjectTimeline employeeProjectTimelineData={employeeDetails?.projectHistory ?? []} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const EmployeeProfilePageWrapper = () => (
  <EntityProvider>
    <EmployeeProfilePage />
  </EntityProvider>
);

export default EmployeeProfilePageWrapper;
