import FlagIcon from '@mui/icons-material/Flag';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RouteIcon from '@mui/icons-material/Route';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { CategoryDTO } from 'src/Models/CategoryModels';
import { CategoryService } from 'src/services/CategoryService';
import { EmployeeService } from 'src/services/EmployeeService';
import { Category } from 'src/services/FeedbackType';
import { calculateTimeDifferenceInDaysOrWeeksWithText } from 'src/utilities/DateUtilities';
import { SquadsContext } from 'src/utilities/SquadsContext';

import ChartHeading from '../dashboardShared/ChartHeading';
import QuickFilterCard from '../dashboardShared/QuickFilterCard';
import QuickFilterFeedbackCategoryModal from './QuickFilterFeedbackCategoryModal';
import { useDashboardContext } from './context/DashboardContext';
import { useFilterContext } from './context/FilterContext';

const QuickFilters: React.FC = () => {
  const { selectedFilter, setSelectedFilter, metrics, startDate, endDate } = useFilterContext();
  const flaggedCount = metrics?.quickFilterCounts.flaggedCount;
  const negativeSentimentCount = metrics?.quickFilterCounts.negativeSentimentCount;
  const notOnAPathOrCertCount = metrics?.quickFilterCounts.notOnAPathOrCertCount;
  const noFeedbackCount = metrics?.quickFilterCounts.noFeedbackCount;
  const { setEmployees, getAllEmployees, setIsLoading } = useDashboardContext();
  const squadId = useContext(SquadsContext).squad.squadId;
  const [feedbackCategories, setFeedbackCategories] = useState<CategoryDTO[]>([]);
  const [showModal, setShowModal] = useState(false);

  const employeeService = new EmployeeService();
  const categoryService = new CategoryService();

  const handleSelectFilter = (e: React.MouseEvent<HTMLElement>, newFilter: string) => {
    if (newFilter !== selectedFilter) {
      setSelectedFilter(newFilter);
    } else {
      setSelectedFilter('');
    }
  };

  useEffect(() => {
    if (selectedFilter) {
      setEmployeesResult(selectedFilter);
    } else {
      getAllEmployees();
    }
  }, [selectedFilter]);

  const setEmployeesResult = async (filterBy: string) => {
    setIsLoading(true);
    const filteredEmployees = await employeeService.getQuickFilterEmployees(filterBy, squadId, startDate, endDate);
    setEmployees(filteredEmployees);
    setIsLoading(false);
  };

  const handleSelectCategoryFilter = async (e: React.MouseEvent<HTMLElement>) => {
    if (selectedFilter !== 'no-feedback-by-category') {
      setShowModal(true);
      const response = await categoryService.getAllCategoriesForSquad(squadId);
      setFeedbackCategories(response);
    } else {
      setSelectedFilter('');
    }
  };

  const applyCategoryFilter = async (category: Category | null, startDate: Date | null, endDate: Date | null) => {
    if (category) {
      setCategoryFeedbackFilterSelected();
      const response = await employeeService.GetEmployeesNoFeedbackByCategory(category.categoryId, squadId, startDate, endDate);
      setEmployees(response);
    }
  };

  const setCategoryFeedbackFilterSelected = () => {
    if ('no-feedback-by-category' !== selectedFilter) {
      setSelectedFilter('no-feedback-by-category');
    } else {
      setSelectedFilter('no-feedback-by-category');
    }
  };

  return (
    <>
      <ChartHeading>Quick filters</ChartHeading>
      <Grid container direction={{ xs: 'column', md: 'row', lg: 'column' }} spacing={{ xs: 1, md: 1, lg: 1 }}>
        <Grid item md>
          <QuickFilterCard
            title="flagged"
            id="flagged"
            value="flagged"
            count={flaggedCount}
            icon={<FlagIcon color="error" />}
            isSelected={selectedFilter === 'flagged'}
            onSelect={handleSelectFilter}
          />
        </Grid>
        <Grid item md>
          <QuickFilterCard
            title="negative sentiment"
            id="negative-sentiment"
            value="negative-sentiment"
            count={negativeSentimentCount}
            icon={<SentimentVeryDissatisfiedIcon color="error" />}
            isSelected={selectedFilter === 'negative-sentiment'}
            onSelect={handleSelectFilter}
          />
        </Grid>
        <Grid item md>
          <QuickFilterCard
            title="not on a path/cert"
            id="not-on-a-path-cert"
            value="not-on-a-path-cert"
            count={notOnAPathOrCertCount}
            icon={<RouteIcon />}
            isSelected={selectedFilter === 'not-on-a-path-cert'}
            onSelect={handleSelectFilter}
          />
        </Grid>
        <Grid item md>
          <QuickFilterCard
            title={`no feedback ${
              startDate && endDate ? `last ${calculateTimeDifferenceInDaysOrWeeksWithText(startDate.toISOString(), endDate?.toDateString())}` : ''
            }`}
            id="no-feedback"
            value="no-feedback"
            count={noFeedbackCount}
            icon={<QuestionMarkIcon />}
            isSelected={selectedFilter === 'no-feedback'}
            onSelect={handleSelectFilter}
          />
        </Grid>
        <Grid item md>
          <QuickFilterCard
            title={'no feedback by category'}
            id="no-feedback-by-category"
            value="no-feedback-by-category"
            count={noFeedbackCount}
            icon={<SpeakerNotesOffIcon />}
            isSelected={selectedFilter === 'no-feedback-by-category'}
            onSelect={handleSelectCategoryFilter}
          />
        </Grid>
      </Grid>
      <QuickFilterFeedbackCategoryModal
        open={showModal}
        onClose={() => setShowModal(false)}
        feedbackCategories={feedbackCategories}
        applyFilter={applyCategoryFilter}
      />
    </>
  );
};

export default QuickFilters;
