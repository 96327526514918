import { Input } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

interface SearchBarProps {
    suggestions: string[];
    onSearch: (query: string) => void;
}

const CustomInputSearchBar: React.FC<SearchBarProps> = ({ suggestions, onSearch }) => {
    const [inputValue, setInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);

    useEffect(() => {
        if (inputValue) {
            const filtered = suggestions.filter(suggestion =>
                suggestion.toLowerCase().includes(inputValue.toLowerCase())
            );
            setFilteredSuggestions(filtered);
            setShowSuggestions(true);
        } else {
            setFilteredSuggestions([]);
            setShowSuggestions(false);
        }
    }, [inputValue, suggestions]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setInputValue(filteredSuggestions[activeSuggestionIndex]);
            setShowSuggestions(false);
            onSearch(filteredSuggestions[activeSuggestionIndex]);
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            if (activeSuggestionIndex === 0) {
                return;
            }
            setActiveSuggestionIndex(activeSuggestionIndex - 1);
        } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (activeSuggestionIndex === filteredSuggestions.length - 1) {
                return;
            }
            setActiveSuggestionIndex(activeSuggestionIndex + 1);
        }
    };

    const handleClick = (suggestion: string) => {
        setInputValue(suggestion);
        setShowSuggestions(false);
        onSearch(suggestion);
    };

    const handleClear = () => {
        setInputValue('');
        setFilteredSuggestions([]);
        setShowSuggestions(false);
        onSearch('')
    };

    return (
        <div style={{ position: 'relative', width: '300px' }}>
            <div style={{ position: 'relative' }}>
                <Input
                    type="text"
                    value={inputValue}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onFocus={() => setShowSuggestions(true)}
                    onBlur={() => setTimeout(() => setShowSuggestions(false), 100)}
                    style={{
                        width: '100%',
                        padding: '8px 30px 8px 8px',
                        boxSizing: 'border-box'
                    }}
                    placeholder="Search employees by tag..."
                />
                <ClearIcon
                    onClick={handleClear}
                    style={{
                        position: 'absolute',
                        right: '8px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer'
                    }}
                />
            </div>
            {showSuggestions && inputValue && (
                <ul style={{
                    position: 'absolute',
                    top: '100%',
                    left: '0',
                    right: '0',
                    background: 'white',
                    border: '1px solid #ccc',
                    borderTop: 'none',
                    zIndex: 1000,
                    maxHeight: '200px',
                    overflowY: 'auto',
                    listStyle: 'none',
                    margin: 0,
                    padding: 0
                }}>
                    {filteredSuggestions.map((suggestion, index) => (
                        <li
                            key={suggestion}
                            style={{
                                padding: '8px',
                                cursor: 'pointer',
                                background: index === activeSuggestionIndex ? '#f0f0f0' : 'white'
                            }}
                            onMouseDown={() => handleClick(suggestion)}
                        >
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomInputSearchBar;
