import React, { createContext, useContext, useEffect, useState } from 'react';
import { DashboardEmployee } from 'src/Models/EmployeeModels';
import { EmployeeService } from 'src/services/EmployeeService';
import { SquadsContext } from 'src/utilities/SquadsContext';
import useSWR from 'swr';

interface DashboardContextProps {
  employees: DashboardEmployee[];
  setEmployees: React.Dispatch<React.SetStateAction<DashboardEmployee[]>>;
  getAllEmployees: () => any;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardContext = createContext<DashboardContextProps>({
  employees: [],
  setEmployees: () => { },
  getAllEmployees: () => { },
  isLoading: false,
  setIsLoading: () => { },
});

const employeeService = new EmployeeService();

export const DashboardProvider: React.FC = ({ children }) => {
  const [employees, setEmployees] = useState<DashboardEmployee[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { squad } = React.useContext(SquadsContext);

  const getAllEmployees = () => {
    loadEmployees();
  };

  const { data } = useSWR(`api/employees/${squad.squadId}`, async () => await employeeService.getActiveDashboardEmployees(+squad.squadId));

  const loadEmployees = () => {
    setIsLoading(true);
    setEmployees(data || []);
    if (data) setIsLoading(false);
  };

  useEffect(() => {
    loadEmployees();
  }, [data]);

  return (
    <DashboardContext.Provider
      value={{
        employees,
        setEmployees,
        getAllEmployees,
        isLoading,
        setIsLoading,
      }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};
