import { Box } from '@mui/material';
import { ArcElement, Chart, ChartOptions, Tooltip } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { TagDashboardGraphModel } from 'src/Models/TagModels';

interface TagChartProps {
  tagData: TagDashboardGraphModel[];
}

const TagChart: React.FC<TagChartProps> = ({ tagData }) => {
  const getDistinctTags = (data: TagDashboardGraphModel[]) => {
    return data.reduce((acc: TagDashboardGraphModel[], current: TagDashboardGraphModel) => {
      const existingTag = acc.find((tag) => tag.tagName === current.tagName);
      if (!existingTag) {
        acc.push(current);
      }
      return acc;
    }, []);
  };

  const distinctTagData = getDistinctTags(tagData);

  Chart.register(ArcElement, Tooltip);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const amounts = distinctTagData.map((tag) => tag.amountTagged);
  const colors = distinctTagData.map(() => getRandomColor());
  const names = distinctTagData.map((tag) => tag.tagName);

  const chartData = {
    labels: names,
    datasets: [
      {
        data: amounts,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <Box id="top-five-tags-chart" alignSelf="center" sx={{ width: '100%' }}>
      <Bar data={chartData} options={options} />
    </Box>
  );
};

export default TagChart;
